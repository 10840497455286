import { useEffect, useReducer, useState, useRef } from 'react'
import { getPublicCollectionGroupList, getProductsPrices } from '@utils/fetch'
import { useLocation } from 'react-router-dom'
import { useTranslation } from "react-i18next"
import debounce from "lodash.debounce";

/* components */
import Header from '@components/plp/Header'
import Filters from '@components/plp/Filters'
import CollectionList from '@components/plp/CollectionList'
import ListingNotFound from '@components/plp/ListingNotFound'
import Skeleton from '@components/plp/Skeleton'

/* context */
import context, { initialContext } from '@context/plp'
import reducer from '@context/plp/reducer'
import { setCollectionGroup, setPrices, setQueryFilters, setCollectionFilters, clearAllFilters, setFiltering } from '@context/plp/actions'
import { productImpressions } from '@utils/analytics'
import { getActiveFiltersFromURL, getFiltersFromCollections, filterBundleList } from '@utils/listing'
import { emulateScroll } from "@utils/helpers"

function Collection() {
  const [state, dispatch] = useReducer(reducer, initialContext)
  const [contentVisible, setContentVisible] = useState(false)
  const [preview, setPreview] = useState(false)
  const [urlChanged, setUrlChanged] = useState(0)
  const location = useLocation()
  const { t } = useTranslation()
  const initialCollectionGroupRef = useRef([])

  const debouncedUrlChanged = useRef(
      debounce(() => {
        const rootElement = document.getElementById('root')
        if (rootElement) {
          clearAllFilters({ dispatch })
          setTimeout(() => {
            setUrlChanged((new Date()).getTime())
          }, 200);
        }
      }, 500),
  ).current

  useEffect(() => {
    const handleEvent = () => {
      debouncedUrlChanged()
    }

    window.addEventListener('state-changed', handleEvent);
    return () => window.removeEventListener('state-changed', handleEvent)
  }, [])

  useEffect(() => {
    debouncedUrlChanged.cancel()
    const previewParam = window.location.href.indexOf("?preview=") > 0
    setPreview(previewParam)

    const fetchData = async () => {
      try {
        const publishedParam = previewParam ? 0 : 1
        const collectionGroupData = await getPublicCollectionGroupList({ published: publishedParam })

        if (collectionGroupData.status === 200) {
          const collections = collectionGroupData.collections.map((bundleOrGroup) => {
            const filteredBundlesByDates = JSON.parse(bundleOrGroup.bundles).filter(bundle =>
                (new Date(bundle.start_at) <= new Date() && new Date(bundle.end_at) >= new Date())
            )
            return  { ...bundleOrGroup, bundles: JSON.stringify(filteredBundlesByDates) }
          })

          initialCollectionGroupRef.current = collections
          if (!state.isBundlePage) {
              const products = collections.map(collection => {
                  const bundles = JSON.parse(collection.bundles)
                  const products = bundles.map(bundle => {
                      const products = JSON.parse(bundle.products)
                      return products.map(product => {
                        if (product.variant) {
                          return `${product.sku}:${product.variant}`
                        } else if (product.fallback) {
                          return `${product.sku}:${product.fallback}`
                        } else {
                          return `${product.sku}:`
                        }
                      })
                  })
                  return products.flat()
              }).flat().join(',')
              if (products.length){
                  const prices = (await getProductsPrices(products)).map(price => price[0])
                  setPrices({ dispatch }, prices)
              }
          }

          setCollectionGroup({ dispatch }, collections, t)
          emulateScroll()
          const filters = getFiltersFromCollections(collections, t)
          setCollectionFilters({ dispatch, state }, filters, t)

          const filtersFromURL = getActiveFiltersFromURL(window.location.hash.replace('#/', ''), filters)
          setQueryFilters({ dispatch }, filtersFromURL)

          setTimeout(() => setContentVisible(true), 1000)
          productImpressions(collections)
          setTimeout(function () {
            setFiltering({ dispatch }, false)
          }, 100)
        }
      } catch (error) {
        console.log('fetch')
        console.error("Error fetching data:", error)
      }
    }

    fetchData()

    const banner = document.querySelector(
      "#body-container > div > div:nth-child(1) > div > div > picture"
    )
    if (banner) {
      banner.style.display = "block"
    }
  }, [location.search, dispatch, t, urlChanged])

  const filteredCollections = state.collectionGroup.map(collection => {
    const activeFiltersWithUnisex = {
      ...state.filters.active,
      gender: state.filters.active.gender && (state.filters.active.gender.includes('men') || state.filters.active.gender.includes('women'))
        ? [...state.filters.active.gender, 'unisex']
        : state.filters.active.gender || [],
    };
  
    const filteredBundles = collection.bundles.filter(bundle => filterBundleList(bundle, activeFiltersWithUnisex));
    return filteredBundles.length > 0 ? { ...collection, bundles: filteredBundles } : null;
  }).filter(Boolean);

  useEffect(() => {
    const updatedFilters = getFiltersFromCollections(filteredCollections, t);
    if (JSON.stringify(state.filters.all) !== JSON.stringify(updatedFilters)) {
      setCollectionFilters({ dispatch, state }, updatedFilters, t);
    }
  }, [filteredCollections, dispatch, t, state.filters.all]);

  const activeFilters = Object.keys(state.filters.active).length > 0;

  return (
    <context.Provider value={{ state, dispatch }}>
      <div className="b-app">
        {state.loading || state.filterLoading ? (
          <Skeleton visible={true} />
        ) : (
          <div className={`b-container ${contentVisible ? "" : "b-container--hidden"}`}>
            <Header titleKey="plp.COLLECTIONS.TITLE" subtitleKey="plp.COLLECTIONS.DESC" />
            {filteredCollections.length === 0 ? (
              <ListingNotFound />
            ) : (
              <>
                <Filters />
                {state.filterLoading ? (
                  <Skeleton visible={true} />
                ) : (
                  <CollectionList preview={preview} bundles={filteredCollections} activeFilters={activeFilters} prices={state.prices} />
                )}
              </>
            )}
          </div>
        )}
      </div>
    </context.Provider>
  )
}

export default Collection