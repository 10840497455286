import getSymbolFromCurrency from 'currency-symbol-map'
import LocaleCurrency from 'locale-currency'

export const getImageUrl = (sku, variant, imageType = 'hero', prepend = 'NF0A') => {
    return `https://assets.thenorthface.eu/images/t_img/f_auto,h_344,w_344/e_sharpen:60/dpr_2.0/${prepend}${sku}${variant}-${imageType.toUpperCase()}/image.png`
}

export const getDetailImageUrl = (image) => {
    return image
}

export const getDetailZoomImageUrl = (image) => {
    return image.replace('t_Thumbnail/', 't_img/f_auto,h_1250,w_1250/')
}

export const getDiscountPrice = (price, discount) => {
    const discountNumber = Number(discount.replace('%', ''))
    let formattedPrice = price.toString().replace(getCurrency()+ '', '').replace(',','.').replace('&nbsp;', '').replace('zl', '').replace('kr', '').replace('K&#269;', '').replace(/\s/g, '').trim()
    if((formattedPrice.split('.').length -1) > 1) {
        formattedPrice = formattedPrice.replace('.', '')
    }
    return formattedPrice - (formattedPrice * (discountNumber / 100))
}

export function decodeEntities(encodedString) {
    var textArea = document.createElement('textarea');
    textArea.innerHTML = encodedString;
    return textArea.value;
}

export function getStepBarProductVariant(product, selectedConfiguration, fallbackVariant, fallbackAvailability) {
    return selectedConfiguration[product.sku]?.variant ? selectedConfiguration[product.sku].variant : product.variant ? product.variant : fallbackAvailability[product.sku] ? fallbackAvailability[product.sku] : fallbackVariant[product.sku]
}

export function getListProductVariant(productVariant, fallbackVariant, availabilityVariant) {
    return availabilityVariant ? availabilityVariant : productVariant ? productVariant : fallbackVariant
}

export function getQueryParams() {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    return params
}

export function mapRange(number, inMin, inMax, outMin, outMax) {
    return (number - inMin) * (outMax - outMin) / (inMax - inMin) + outMin;
}

export function getCurrency() {
  if (!document.querySelector("#root")) {
    return ''
  }
  const locale = document.querySelector("#root").getAttribute("data-view-id");
  return getSymbolFromCurrency(LocaleCurrency.getCurrency(locale));
}

export function getCurrencyCode() {
    if (!document.querySelector("#root")) {
        return ''
    }
    const locale = document.querySelector("#root").getAttribute("data-view-id");
    return LocaleCurrency.getCurrency(locale)
}

export function getCountryCode() {
    if (!document.querySelector("#root")) {
        return ''
    }
    const locale = document.querySelector("#root").getAttribute("data-view-id");
    return locale.split('_')[1]
}

export function formatPrice(price) {
    return price.replace(',', '.').replace('&nbsp;', ' ').replace('K&#269;', `${getCurrency()} `)
}

export function formatPriceWithCurrency(price) {
    if (!document.querySelector("#root")) {
        return price
    }
    const locale = document.querySelector("#root").getAttribute("data-view-id");
    if(locale === 'sv_SE' || locale === 'pl_PL' || locale === 'en_SE'){
        return price.toFixed(2) + ' ' + getCurrency()
    } else {
        return getCurrency() + ' ' + price.toFixed(2)
    }
}

export function isMobile() {
    return window.screen.width < 1024;
}

export function isTestingEnv() {
  return window.location.hostname.indexOf("www.") < 0;
}

export function emulateScroll() {
    const originalScrollRestoration = window.history.scrollRestoration
    window.history.scrollRestoration = 'manual'

    setTimeout(() => {
        const scrollY = window.scrollY;
        requestAnimationFrame(() => {
            window.scrollTo(0, scrollY + 1)
            requestAnimationFrame(() => {
                window.scrollTo(0, scrollY)
            })
        })

        window.history.scrollRestoration = originalScrollRestoration
    }, 10)
}

export function prependLocaleInUrl(link) {
    if (link.indexOf('http') === 0) {
        return link
    }
    try {
        const url = URL.parse(window.location)
        const parts = url.pathname.split('/')

        return parts.length > 1 ? `/${parts[1]}/${link}` : link
    } catch (e) {

    }
    return link
}