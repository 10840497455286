import { Link } from "react-router-dom"
import { useTranslation } from "react-i18next"
import "../../i18n"

/* styles */
import style from "@styles/plp/BundleItem.module.scss"

/* utils */
import { onGoToBundle } from "@utils/analytics"
import { getImageUrl, getDiscountPrice, getListProductVariant, getCurrency } from "@utils/helpers"

export default function BundleItem({ bundle, preview, threeItemsLayout, forceNoImage, context, prices }) {
  const { products, data, translations } = bundle
  const { t, i18n } = useTranslation("translation")
  const detailUrl = "/bundle-detail/"
  let parsedProducts = []

  const currentLanguage = i18n.language
  
  const getLocalizedBundleName = () => {
    if (translations?.[currentLanguage]) {
      return translations[currentLanguage];
    }
  
    const localizedKey = Object.keys(translations || {}).find(key => key.startsWith(currentLanguage));
    if (localizedKey) {
      return translations[localizedKey];
    }
  
    return bundle.name;
  }

  try {
    parsedProducts = typeof products === 'string' ? JSON.parse(products) : products
    if (!Array.isArray(parsedProducts)) {
      parsedProducts = []
    }
  } catch (error) {
    console.error("Error parsing products JSON:", error)
    parsedProducts = []
  }

  const getThumbsClassName = () => {
    let className = style.thumbs
    if (!threeItemsLayout) className += ' '
    className += ' ' + style.desktop
    return className
  }

  return (
    <div className={`${style.container} ${(!bundle.image || forceNoImage) ? style.noImage : ''}`}>
      <div className={style.productInfo}>
        <h2 className={style.name}>{getLocalizedBundleName()}</h2>
        <div className={getThumbsClassName()}>
          {parsedProducts.map((product, index) => (
            <Thumb key={index} data={{ index, product, data, bundleId: bundle.cross_bundle_id || bundle.id, bundleName: bundle.name, preview, bundle, prices, context }} />
          ))}
        </div>
        <div className={style.info}>
          <div className={`${style.thumbs} ${style.mobile}`}>
            {parsedProducts.map((product, index) => (
              <Thumb key={index} data={{ index, product, data, bundleId: bundle.cross_bundle_id || bundle.id, bundleName: bundle.name, preview, bundle, prices, context }} />
            ))}
          </div>
          {context !== 'collection' && (
            bundle.discount > 0 && bundle.price ? (
              <>
                <span className={style.price}>
                  {getCurrency()} {getDiscountPrice(bundle.price, bundle.discount).toFixed(2)}
                </span>
                <span className={style.priceCrossed}>
                  {getCurrency()} {bundle.price.toFixed(2)}
                </span>
                <span className={style.discount}>
                  {t("plp.SAVE").replace('X', bundle.discount)}
                </span>
              </>
            ) : (
              bundle.price && (
                <span className={style.fullPrice}>
                  {getCurrency()} {bundle.price.toFixed(2)}
                </span>
              )
            )
          )}
        </div>
        <Link onClick={() => onGoToBundle(bundle, JSON.parse(data))} to={detailUrl + (bundle.cross_bundle_id || bundle.id) + (preview ? '?preview=true' : '')} className={style.cta}>
          {context === 'collection' ? t("plp.SHOP-NOW") : t("plp.SHOP-BUNDLE")}
        </Link>
      </div>
      {bundle.image && !forceNoImage && (
        <div className={style.imageSection}>
          <img src={bundle.image} alt={t("plp.EDITORIAL-IMAGE")} className={style.promoImage} />
        </div>
      )}
    </div>
  )
}

function Thumb(props) {
  const { index, product, bundleId, bundleName, preview, bundle, prices, context } = props.data
  let { data } = props.data
  typeof data !== 'object' ? data = JSON.parse(data) : data = data
  const { t } = useTranslation("translation")
  const detailUrl = `/bundle-detail/${bundleId}?step=${index}`
  const variant = getListProductVariant(product.variant, product.fallback, data?.fallback_variants?.[product.sku])

  const getProductPrice = (sku) => {
    const product = prices.find(product => {
      return product.id.substring(5).startsWith(sku)
    })
    if (product && product?.price) {
      return `${getCurrency()} ${parseFloat(product.price).toFixed(2)}`
    }

    if (sku.length > 5) {
      return getProductPrice(sku.substring(0, 5))
    }

    return ''
  }

  const firstImg = {
    bundleId,
    bundleName,
    sku: product.sku,
    variant,
    type: product.image1type || 'hero',
    prepend: data.ids[product.sku]?.substring(0, 2) + data.ids[product.sku]?.substring(3, 5)
  }
  const secondImg = {...firstImg, type: product.image2type || 'alt1'}

  return (
    <div className={style.thumbWrapper}>
      <Link onClick={() => onGoToBundle(bundle, data)} to={detailUrl + (preview ? '&preview=true' : '')}>
        <img
          alt={product.sku}
          className={`${style.thumbImage} ${style.thumbImageDefault}`}
          src={getImageUrl(firstImg.sku, firstImg.variant, firstImg.type, firstImg.prepend)}
        />
      </Link>
      <Link onClick={() => onGoToBundle(bundle, data)} to={detailUrl + (preview ? '&preview=true' : '')}>
        <img
          alt={product.sku}
          className={`${style.thumbImage} ${style.thumbImageAlt}`}
          src={getImageUrl(secondImg.sku, secondImg.variant, secondImg.type, secondImg.prepend)}
        />
      </Link>
      {context === 'collection' && (
          <div className={style.collectionPrice}>{getProductPrice(`${product.sku}:${secondImg.variant}`)}</div>
      )}
    </div>
  )
}