import { getQueryParams } from "@utils/helpers"
import { filterBundleList } from "@utils/listing"

const actions = {
    SET_BUNDLES: 'SET_BUNDLES',
    SET_BUNDLE_GROUP: 'SET_BUNDLE_GROUP',
    SET_COLLECTION_GROUP: 'SET_COLLECTION_GROUP',
    SET_QUERY_FILTERS: 'SET_QUERY_FILTERS',
    SET_COMBINED_RESULTS: 'SET_COMBINED_RESULTS',
    TOGGLE_FILTER: 'TOGGLE_FILTER',
    SET_LOADING: 'SET_LOADING',
    SET_FILTERING: 'SET_FILTERING',
    SET_BUNDLES_FILTERS: 'SET_BUNDLES_FILTERS',
    SET_COLLECTIONS_FILTERS: 'SET_COLLECTIONS_FILTERS',
    SET_FILTER_LOADING: 'SET_FILTER_LOADING',
    CLEAR_ALL_FILTERS: 'CLEAR_ALL_FILTERS',
    SET_SORTING: 'SET_SORTING',
    SET_PRODUCT_PRICES: 'SET_PRODUCT_PRICES',
}

export default actions

export function setBundles({ dispatch, t }, bundles) {
    const translatedBundles = bundles.map(bundle => ({
        ...bundle,
        products: JSON.parse(bundle.products),
        data: JSON.parse(bundle.data),
        accessoriesTranslated: bundle.accessories_bundle === 1 ? t('accessories') : t('not accessories')
    }))
    
    dispatch({
        type: actions.SET_BUNDLES,
        bundles: translatedBundles
    })
}

export function setPrices({ dispatch }, prices) {
    dispatch({
        type: actions.SET_PRODUCT_PRICES,
        prices
    })
}

export const setBundleGroup = ({ dispatch, t }, bundleGroup) => {
    const translatedBundleGroup = bundleGroup.map(bundle => ({
        ...bundle,
        bundles: JSON.parse(bundle.bundles).map(subBundle => ({
            ...subBundle,
            accessoriesTranslated: subBundle.accessories_bundle === 1 ? t('accessories') : t('not accessories')
        }))
    }))

    dispatch({
        type: actions.SET_BUNDLE_GROUP,
        bundleGroup: translatedBundleGroup
    })
}

export const setCollectionGroup = ({ dispatch, t }, collectionGroup) => {
    const translatedCollectionGroup = collectionGroup.map(collection => ({
        ...collection,
        bundles: JSON.parse(collection.bundles).map(subBundle => ({
            ...subBundle
        }))
    }))

    dispatch({
        type: actions.SET_COLLECTION_GROUP,
        collectionGroup: translatedCollectionGroup
    })
}

export const setCombinedResults = ({ dispatch }, combinedResults) => {
    dispatch({
        type: actions.SET_COMBINED_RESULTS,
        combinedResults: combinedResults,
    })
}

export function setBundlesFilters({ dispatch, state }, filters, t) {
    dispatch({ type: actions.SET_BUNDLES_FILTERS, filters })

    const queryFilters = {}
    const params = getQueryParams()
    const activableKey = Object.keys(params).filter(key => key !== 'preview')[0]
    const searchParams = window.location.hash.replace('#/', '')

    // If search params isn't empty, the query filters have been within Listing.js
    if (searchParams === '' && activableKey) {
        const newValue = t(params[activableKey]) ? t(params[activableKey]).toLowerCase() : ""
        if (activableKey !== 'accessories') {
            queryFilters[activableKey] = newValue.split(',')
        } else {
            queryFilters[activableKey] = true
        }

        if (!state.filters.active[activableKey] || state.filters.active[activableKey] !== newValue) {
            const combinedResults = [...state.bundles, ...state.bundleGroup]
            dispatch({ type: actions.SET_FILTER_LOADING, filterLoading: true })

            const filteredResults = combinedResults.filter(bundle => filterBundleList(bundle, queryFilters))

            dispatch({
                type: actions.SET_QUERY_FILTERS,
                queryFilters,
                result: filteredResults,
                t,
            })
        }
    }
}

export function setCollectionFilters({ dispatch, state }, filters, t) {
    dispatch({ type: actions.SET_COLLECTIONS_FILTERS, filters })

    const queryFilters = {}
    const params = getQueryParams()
    const activableKey = Object.keys(params).filter(key => key !== 'preview')[0]

    if (activableKey) {
        const newValue = t(params[activableKey]) ? t(params[activableKey]).toLowerCase() : ""
        queryFilters[activableKey] = newValue

        if (!state.filters.active[activableKey] || state.filters.active[activableKey] !== newValue) {
            const collectionResults = [...state.collectionGroup]
            dispatch({ type: actions.SET_FILTER_LOADING, filterLoading: true })

            const filteredResults = collectionResults.filter(collection => filterBundleList(collection, queryFilters, t))

            dispatch({
                type: actions.SET_QUERY_FILTERS,
                queryFilters,
                result: filteredResults,
                t,
            })
        }
    }
}

export function toggleFilter({ state, dispatch }, filter) {
    const { property, value } = filter
    const activeFilters = { ...state.filters.active }
    const currentFilters = activeFilters[property] || []
  
    activeFilters[property] = currentFilters.includes(value) 
      ? currentFilters.filter(item => item !== value) 
      : [...currentFilters, value]
  
    if (activeFilters[property].length === 0) {
      delete activeFilters[property]
    }
  
  
    const result = [...state.bundles, ...state.bundleGroup].filter(bundle => {
      const isFiltered = filterBundleList(bundle, activeFilters)
      return isFiltered
    })
    
    dispatch({ type: actions.TOGGLE_FILTER, activeFilters, result })
}

export function setSorting({ dispatch, state }, sortOptionId) {
    let sortedBundles = [...state.filters.result]

    switch (sortOptionId) {
        case 1: // Price: Low to High
            sortedBundles.sort((a, b) => a.price - b.price)
            break
        case 2: // Price: High to Low
            sortedBundles.sort((a, b) => b.price - a.price)
            break
        default:
            break
    }

    dispatch({ type: actions.SET_SORTING, sortedBundles, sortOption: sortOptionId })
}

export function setLoading({ dispatch }, loading) {
    dispatch({
        type: actions.SET_LOADING,
        loading
    })
}

export function setFiltering({ dispatch }, filtering) {
    dispatch({
        type: actions.SET_FILTERING,
        filtering
    })
}

export function setFilterLoading({ dispatch }, loading) {
    dispatch({
        type: actions.SET_FILTER_LOADING,
        loading
    })
}

export function clearAllFilters({ dispatch }) {
    dispatch({ 
        type: actions.CLEAR_ALL_FILTERS
    })
}

export function setQueryFilters({ dispatch }, filters) {
    dispatch({
        type: actions.SET_QUERY_FILTERS,
        queryFilters: filters,
    })
}